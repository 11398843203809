import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {Link} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents,
  getResourceCategoryUrl
} from '../lib/helpers'
import '../styles/resource-archive-list.css'
import Search from "../components/resources-search"
const searchIndices = [{ name: `Resources`, title: `Resources` }]

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query ResourcesPage {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }

  }
`

const ResourcesPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        
        <div className="resource-archive-list wrapper">
          <h3>Resources</h3>
          <div className="resources-search"><Search indices={searchIndices} /></div>
        
        <div className="resource-archive-outer">
        <div className="resource-archive-inner">
        {catNodes && catNodes.map(cat => (
              <p><Link to={getResourceCategoryUrl(cat.slug.current)}>{cat.title}</Link></p>
            ))}
        </div>
        </div>
      </div>

      </Container>
    </Layout>
  )
}

export default ResourcesPage
